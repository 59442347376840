import React, { Fragment, useCallback, useMemo } from 'react'
import { isEmployeeRole } from '../../../utils/roles'
import useProfile from '../../../hooks/useProfile/useProfile'
import * as modalButtonStyles from './itemButtons.module.scss'
import { ItemButtonsProps } from './types'
import Svg from '../svg/svg'

export const ItemButtons = ({
   itemButtons = [],
   permissions = [],
}: ItemButtonsProps) => {
   const { profile } = useProfile()
   const userRoles = useMemo(() => profile?.Roles || [], [profile])
   const isEmployeeUser = useMemo(() => isEmployeeRole(userRoles), [userRoles])

   const isPermitted = useCallback((arr: string[], value: string) => {
      if (!isEmployeeUser) {
         if (value === '') return true // return as this doesn't require a permission or type
      }
      return arr.some((item) => item === value)
   }, [])

   return (
      <>
         <div className={`${modalButtonStyles.buttonsContainer} item-buttons`}>
            {itemButtons.map((item, index) => (
               <Fragment key={`${item.title}_${index}`}>
                  {isPermitted(permissions, item.type) && (
                     <button
                        id={String(index)}
                        className={`${modalButtonStyles.itemButton} itemButton-${index}`}
                        onClick={item.action}
                        title={item.title}
                        type="button"
                     >
                        <div className={modalButtonStyles.innerButtonContent}>
                           <div className={modalButtonStyles.iconWrapper}>
                              <Svg id={item.name} width={30} height={30} />
                           </div>
                           <div className={modalButtonStyles.iconTitle}>
                              {item.title.toUpperCase()}
                           </div>
                        </div>
                     </button>
                  )}
               </Fragment>
            ))}
         </div>
      </>
   )
}
