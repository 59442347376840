import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal } from '..'
import { useMediaQuery } from 'react-responsive'
import * as styles from './importModal.module.scss'
import { Col, Row } from 'react-grid-system'
import { ProgressIndicator } from '../progressIndicator/progressIndicator'
import HelpLink from '../helpLink/helpLink'
import { StaticImage } from 'gatsby-plugin-image'
import ImportInitialView from './importInitialView'
import ImportStatusView from './importStatusView'
import { ImportModalProps } from './types'

export enum ImportModalViews {
   INITIAL = 1,
   IMPORTING = 2,
   SUCCESS = 3,
   ERROR = 4,
}

const ImportModal = ({
   variant,
   isOpen,
   handleClose,
   importSelectedFile,
   handleSuccessfulImport,
   isImportingData,
   importError,
   importFileErrors,
   isImportingSuccess,
   totalImported,
}: ImportModalProps) => {
   const [currentView, setCurrentView] = useState<ImportModalViews>(
      ImportModalViews.INITIAL
   )
   const [percentage, setPercentage] = useState<number>(0)
   const [selectedFile, setSelectedFile] = useState<File | null>(null)
   const minScreenWidth = useMediaQuery({ query: '(max-width: 575px)' })

   const handleReset = () => {
      setPercentage(0)
      setSelectedFile(null)
      setCurrentView(ImportModalViews.INITIAL)
   }

   useEffect(() => {
      if (isOpen === false) {
         handleReset()
      }
   }, [isOpen])

   useEffect(() => {
      if (
         currentView !== ImportModalViews.INITIAL &&
         currentView !== ImportModalViews.ERROR
      ) {
         const intervalId = setInterval(() => {
            setPercentage((prevCount) => prevCount + 1)
         }, 100)

         // stop progress bar at 98 in case import has not completed
         if (percentage === 98) {
            return clearInterval(intervalId)
         }

         if (isImportingSuccess) {
            setPercentage(100)
            return clearInterval(intervalId)
         }

         return () => clearInterval(intervalId)
      }
   }, [percentage, currentView, isImportingSuccess])

   useEffect(() => {
      if (isImportingSuccess) {
         setPercentage(100)
         setCurrentView(ImportModalViews.SUCCESS)
      }
   }, [isImportingSuccess])

   useEffect(() => {
      if (importError || importFileErrors?.length > 0) {
         setPercentage(100)
         setCurrentView(ImportModalViews.ERROR)
      }
   }, [importError, importFileErrors])

   useEffect(() => {
      if (isImportingData) {
         setCurrentView(ImportModalViews.IMPORTING)
      }
   }, [isImportingData])

   const leftButtonDisabled = useMemo(
      () =>
         currentView === ImportModalViews.IMPORTING ||
         currentView === ImportModalViews.SUCCESS ||
         currentView === ImportModalViews.ERROR,
      [currentView]
   )

   const leftButtonText = useMemo(
      () =>
         currentView === ImportModalViews.INITIAL ? 'Cancel' : 'Cancel Import',
      [currentView]
   )

   const handleLeftButtonClick = useMemo(
      () =>
         currentView === ImportModalViews.IMPORTING ? handleReset : handleClose,
      [currentView]
   )

   const rightButtonDisabled = useMemo(
      () =>
         (currentView === ImportModalViews.INITIAL && !selectedFile) ||
         currentView === ImportModalViews.IMPORTING,
      [currentView, selectedFile]
   )

   const rightButtonText = useMemo(() => {
      switch (currentView) {
         case ImportModalViews.INITIAL:
            return 'Import'
         case ImportModalViews.IMPORTING:
            return 'Close'
         case ImportModalViews.SUCCESS:
         case ImportModalViews.ERROR:
         default:
            return 'Close'
      }
   }, [currentView])

   const handleRightButtonClick = useMemo(() => {
      switch (currentView) {
         case ImportModalViews.INITIAL:
            return async () => {
               await importSelectedFile(selectedFile)
               setCurrentView(ImportModalViews.IMPORTING)
            }
         case ImportModalViews.SUCCESS:
            return handleSuccessfulImport
         default:
            return handleClose
      }
   }, [currentView, selectedFile])

   const progressView = useMemo(
      () => (currentView === ImportModalViews.ERROR ? 2 : currentView - 1),
      [currentView]
   )

   const title = useMemo(() => `Import ${variant}`, [variant])

   const helpButtonPath = useMemo(
      () =>
         variant === 'Connections'
            ? 'connections-import'
            : 'add-edit-delete-locations#import',
      [variant]
   )

   return (
      <Modal
         isModalVisible={isOpen}
         closeModal={handleClose}
         maxWidth={''}
         zIndex={'999'}
         stickyHeader={true}
         title={title}
         headerColour={'#FFCA05'}
         hideCloseIcon
      >
         <div className={styles.contentWrapper}>
            <Row>
               <Col xs={6} sm={6} md={6} lg={6} className={styles.column}>
                  {currentView === ImportModalViews.INITIAL ? (
                     <ImportInitialView
                        variant={variant}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                     />
                  ) : (
                     <ImportStatusView
                        variant={variant}
                        currentView={currentView}
                        importError={importError}
                        isImportingSuccess={isImportingSuccess}
                        importFileErrors={importFileErrors}
                        totalImported={totalImported}
                        percentage={percentage}
                        helpButtonPath={helpButtonPath}
                     />
                  )}
                  <div className={styles.bottomSection}>
                     <div className={styles.buttonsWrapper}>
                        <Button
                           minWidth="18rem"
                           onClick={handleLeftButtonClick}
                           variant="tertiary"
                           disabled={leftButtonDisabled}
                           type="button"
                           className={leftButtonDisabled && styles.hideButton}
                        >
                           {leftButtonText}
                        </Button>
                        <Button
                           minWidth="18rem"
                           onClick={handleRightButtonClick}
                           variant="primary"
                           disabled={rightButtonDisabled}
                           type="button"
                           className={
                              rightButtonDisabled &&
                              currentView !== ImportModalViews.INITIAL &&
                              styles.hideButton
                           }
                        >
                           {rightButtonText}
                        </Button>
                     </div>
                     <div
                        className={
                           minScreenWidth
                              ? styles.progressIndicatorWrapperLeft
                              : styles.progressIndicatorWrapperCenter
                        }
                     >
                        <ProgressIndicator
                           totalViews={3}
                           currentView={progressView}
                        />
                     </div>
                  </div>
               </Col>
               <Col xs={6} sm={6} md={6} lg={6} className={styles.column}>
                  <div className={styles.rightColumn}>
                     <div className={styles.helpLinkWrapper}>
                        <HelpLink
                           href={`https://help.sharemytoolbox.com/knowledge/${helpButtonPath}`}
                        />
                     </div>
                     <StaticImage
                        src={'../../../assets/images/importItems/excel.png'}
                        className={styles.topImage}
                        formats={['png']}
                        alt={'Download the import template'}
                     />
                     <StaticImage
                        src={'../../../assets/images/importItems/upload.png'}
                        className={styles.middleImage}
                        formats={['png']}
                        alt={`Upload your ${variant}`}
                     />
                     <StaticImage
                        src={'../../../assets/images/importItems/tools.png'}
                        className={styles.bottomImage}
                        formats={['png']}
                        alt={`View your updated ${variant}`}
                     />
                  </div>
               </Col>
            </Row>
         </div>
      </Modal>
   )
}

export default ImportModal
