import { NavItems } from '../../hooks/useNavigation/useNavigation.type'
import { ROUTES } from '../routes'

// sub nav lists
import { historyNavList } from './subnavigation/history'
import { settingsNavList } from './subnavigation/settings'
import { profileNavList } from './subnavigation/profile'
// import { itemsNavList } from './subnavigation/items'
// import { actionsNavList } from './subnavigation/actions'; // may be re-introduced
import { helpNavList } from './subnavigation/help'

export const navList: NavItems = [
   {
      icon: 'dashboard',
      label: 'Dashboard',
      route: ROUTES.dashboard,
   },
   {
      icon: 'tasks',
      label: 'Tasks',
      route: ROUTES.tasks,
   },
   {
      icon: 'images-nav',
      label: 'Images',
      route: ROUTES.images,
   },
   {
      icon: 'subscriptions',
      label: 'Subscriptions',
      route: ROUTES.subscriptions,
   },
   {
      icon: 'users',
      label: 'Users',
      route: ROUTES.users,
   },
   {
      icon: 'global-prices',
      label: 'Global Prices',
      route: ROUTES.global_prices,
   },
   {
      icon: 'discounts',
      label: 'Discounts',
      route: ROUTES.discounts,
   },
   {
      icon: 'items',
      // children: itemsNavList, reinstate if mass transfer is required
      label: 'Items',
      route: ROUTES.items,
   },
   {
      icon: 'actions',
      // children: actionsNavList, reinstate if required
      label: 'Actions',
      route: ROUTES.actions,
   },
   {
      icon: 'connections',
      label: 'Connections',
      route: ROUTES.connections,
   },
   {
      icon: 'locations',
      label: 'Locations',
      route: ROUTES.locations,
   },
   {
      icon: 'audit',
      label: 'Audits',
      route: ROUTES.audits,
   },
   {
      icon: 'notes',
      label: 'Notes',
      route: ROUTES.notes,
   },
   {
      children: historyNavList,
      icon: 'history',
      label: 'History',
      route: ROUTES.history,
   },
   {
      children: settingsNavList,
      icon: 'settings',
      label: 'Settings',
      route: ROUTES.settings,
   },
   {
      children: profileNavList,
      icon: 'profile',
      label: 'Profile',
      route: ROUTES.profile,
   },
   {
      icon: 'subscriptions',
      label: 'Subscription',
      route: ROUTES.profile_subscriptions,
   },
   {
      children: helpNavList,
      icon: 'help',
      label: 'Help',
      route: ROUTES.help,
   },
   {
      icon: 'signout',
      label: 'Sign Out',
      route: ROUTES.logout,
   },
]
