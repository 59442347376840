// extracted by mini-css-extract-plugin
export var hidden = "navItem-module--hidden--e7460";
export var isActive = "navItem-module--is-active--1f305";
export var navItem = "navItem-module--nav-item--7f511";
export var navItemArrow = "navItem-module--nav-item-arrow--f2f89";
export var navItemArrowActive = "navItem-module--nav-item-arrow-active--509aa";
export var navItemChild = "navItem-module--nav-item-child--8d4c3";
export var navItemCircle = "navItem-module--nav-item-circle--80386";
export var navItemIcon = "navItem-module--nav-item-icon--3343e";
export var navItemLink = "navItem-module--nav-item-link--5ccdf";
export var navItemPrimary = "navItem-module--nav-item-primary--67231";
export var smallText = "navItem-module--smallText--e133c";