import React from 'react'
import Datepicker from 'react-datepicker'
import classNames from 'classnames'
import { BsCalendar3 } from 'react-icons/bs'

import { FieldWrapper } from '..'

import { Props } from './dateInput.type'
import 'react-datepicker/dist/react-datepicker.css'
import * as styles from './dateInput.module.scss'

const DateInput: React.FC<Props> = (props) => {
   const {
      description,
      insetDescription = false,
      error,
      disabled = false,
      id,
      label,
      maxDate,
      minDate,
      noMargin = false,
      onChange,
      placeholder,
      required,
      selectedDate,
      withWrappers = true,
      popperPlacement,
   } = props

   const handleDatepickerChange = (date: Date) => {
      if (onChange) {
         onChange(date)
      }
   }

   const renderElement = () => (
      <>
         <div className={styles.iconWrapper}>
            <Datepicker
               className={classes}
               maxDate={maxDate}
               minDate={minDate}
               name={id}
               id={id}
               onChange={handleDatepickerChange}
               selected={selectedDate}
               dateFormat="MM/dd/yyyy"
               placeholderText={placeholder || ''}
               disabled={disabled}
               popperPlacement={popperPlacement ? popperPlacement : undefined}
            />
            <label htmlFor={id} className={styles.icon}>
               <BsCalendar3 size={18} style={{ zIndex: -1 }} />
            </label>
         </div>
         {/* {insetDescription && <span className={styles.insetDescription}>{insetDescription}</span>} */}
      </>
   )

   const classes = classNames(styles.wrapper, {
      [styles.error]: !!error,
   })

   if (!withWrappers) {
      return renderElement()
   }

   return (
      <FieldWrapper
         description={description}
         insetDescription={insetDescription}
         disabled={disabled}
         error={error}
         id={id}
         label={label}
         required={required}
         noMargin={noMargin}
      >
         {renderElement()}
      </FieldWrapper>
   )
}

export default DateInput
