import React from 'react'
import { IconButton } from '../../common'
import { Actions } from '../../../constants/tables'
import { ActionButtonProps } from './types'

const CrudActionButtons = ({
   cell,
   action,
   editAction,
   deleteAction,
   exportAction,
}: ActionButtonProps) => (
   <>
      {editAction && (
         <IconButton
            id="edit"
            action={() => action(Actions.Edit, cell.value)}
            tooltip="Edit"
         />
      )}
      {deleteAction && (
         <IconButton
            id="delete"
            action={() => action(Actions.Delete, cell.value)}
            tooltip="Delete"
         />
      )}
      {exportAction && (
         <IconButton
            id="export-row"
            action={() => action(Actions.Export, cell.value)}
            tooltip="Export"
            style={{
               marginLeft: '5px',
            }}
         />
      )}
   </>
)

export default CrudActionButtons
