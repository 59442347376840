import { useState } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { NoteDetailsResponse, UseNoteDetails } from './useNoteDetails.type'

export default function (): UseNoteDetails {
   const [noteId, setNoteId] = useState<number | null>(null)
   const request = useAPI()
   const { data, isLoading, isRefetching } = useQuery(
      ['noteDetails', noteId],
      getNoteDetailsFromAPI,
      { enabled: !!noteId }
   )

   async function getNoteDetailsFromAPI(): Promise<
      AxiosResponse<NoteDetailsResponse>
   > {
      return request.get(`${PATH.NOTES.DETAILS}/${noteId}`)
   }

   const getNote = (noteId: number) => {
      setNoteId(noteId)
   }

   return {
      getNote,
      isLoading: isLoading || isRefetching,
      note: data?.data,
   }
}
