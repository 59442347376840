import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { useEffect, useState } from 'react'

export default (): UseNews => {
   const request = useAPI()
   const [newsArticles, setNewsArticles] = useState<NewsArticleDetails[]>([])
   const [errorMessage, setErrorMessage] = useState<string | null>(null)

   const { data, isLoading, isSuccess, refetch, isRefetching, isError } =
      useQuery('news', getNewsArticlesFromAPI, {
         onError: (
            error: AxiosError<{ message?: string; Message?: string }>
         ) => {
            const msg =
               error?.response?.data?.Message ?? error?.response?.data?.message
            setErrorMessage(msg)
         },
      })

   async function getNewsArticlesFromAPI(): Promise<
      AxiosResponse<NewsResponse>
   > {
      return await request.get(PATH.NEWS.LIST_ARTICLES)
   }

   useEffect(() => {
      if (!isError || isLoading) {
         setErrorMessage(null)
      }
   }, [isError, isLoading])

   useEffect(() => {
      refetch()
   }, [])

   useEffect(() => {
      if (data && isSuccess) {
         const getImageFromData = (htmlString?: string) => {
            const parser = new DOMParser()
            const doc = parser.parseFromString(htmlString, 'text/html')
            return doc.querySelector('img')?.src
         }
         const articles = data.data?.data?.slice(0, 4).map((item) => ({
            title: item.title,
            link: item.link,
            imageSrc: getImageFromData(item.description.$cdata_section),
         }))
         setNewsArticles(articles)
      }
   }, [data, isSuccess])

   return {
      list: newsArticles,
      isLoading: isLoading || isRefetching,
      isSuccess,
      isError,
      error: errorMessage,
   }
}
