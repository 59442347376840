// @ts-ignore
import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-grid-system'
import { FaCheck } from 'react-icons/fa'
import classNames from 'classnames'
import { TABLE } from '../../../constants/tables'
import { LoadingOverlay, Image, KeyValue, Svg } from '../../common'
import { Actions } from '../../../constants/tables'
import { format } from 'date-fns'
import * as styles from './tile.module.scss'
import * as tileStyles from './tile-new.module.scss'
import { ToolTypes } from '../../../types/index'
import { useSettings } from '../../../hooks'
import { formatLocaleDate } from '../../../utils/functions'
import { TileProps } from './types'

const Tile = ({
   totalCount,
   data,
   setBypass,
   getItem,
   isListLoading: isLoading,
   isListRefetching: isRefetching,
}: TileProps) => {
   const { settings } = useSettings()

   const [isPickerVisibile, _setIsPickerVisible] = useState<boolean>(false)
   const [_selected, setSelected] = useState([]) // this will be sent to the api when available
   const [itemData, setItemData] = useState(data)
   const [toggle, setToggle] = useState<boolean>(false)

   useEffect(() => {
      if (toggle) {
         selectAll()
      } else {
         unSelectAll()
      }
   }, [toggle])

   useEffect(() => {
      setItemData(data)
   }, [])

   useEffect(() => {
      setItemData(
         data.map((item: {}) =>
            Object.assign({}, { ...item, isSelected: false })
         )
      )
   }, [data])

   const toggler = () => {
      setToggle(!toggle)
   }

   const setAllItems = (isTrue: boolean) => {
      const updatedItems = itemData.map((item: {}) =>
         Object.assign({}, { ...item, isSelected: isTrue })
      )
      setItemData(updatedItems)
   }

   const selectAll = () => {
      const arr = []
      setAllItems(true)
      itemData.forEach((item: { Id: number | string }) => {
         arr.push(item.Id)
      })
      setSelected(arr)
   }

   const unSelectAll = () => {
      setAllItems(false)
      setSelected([])
   }

   const handleActionForm = (_action, item) => {
      setBypass(false)
      getItem(item.Id, item.ToolLoanId, item.KitId)
   }

   const renderTileLocation = (item: any) => {
      const warehouseIconStyle = {
         position: 'relative',
         top: '2px',
      }
      const locationIconStyle = {
         marginRight: '8px',
         position: 'relative',
         top: '3px',
      }
      return (
         <>
            <span>
               {item?.DefaultLocation === item?.CurrentLocation ? (
                  <Svg
                     id="warehouse"
                     height="34"
                     width="34"
                     style={warehouseIconStyle}
                  />
               ) : (
                  <Svg style={locationIconStyle} id="location" width="16" />
               )}
            </span>
            <span>{item?.CurrentLocation}</span>
         </>
      )
   }

   return (
      <>
         <div className={styles.tableHeaderArea}>
            {(isLoading || isRefetching) && <LoadingOverlay />}
            <div className={styles.selectAllItems}>
               <div
                  className={classNames(styles.selectAllWrapper, {
                     [styles.open]: isPickerVisibile,
                  })}
               >
                  <button className={styles.selectAllButton} onClick={toggler}>
                     {toggle ? (
                        <div className={styles.toggleOn}>
                           <FaCheck />
                        </div>
                     ) : (
                        <div className={styles.toggleOff} />
                     )}
                  </button>
               </div>
            </div>

            <div className={styles.tableTitle}>
               {totalCount} {TABLE.COUNT_LABEL}
            </div>
         </div>

         {!!itemData?.length && (
            <Row gutterWidth={25}>
               {
                  // having to use `index` as key as there seems to be duplicate ID's from API which is causing render issues
                  itemData.map((item: any, index) => {
                     return (
                        <Col
                           key={index}
                           xs={12}
                           xl={6}
                           className={tileStyles.column}
                        >
                           <div
                              className={classNames(tileStyles.tile, {
                                 [tileStyles[item.StatusDesc.toLowerCase()]]:
                                    true,
                              })}
                              onClick={() =>
                                 handleActionForm(Actions.Edit, item)
                              }
                           >
                              <div className={tileStyles.tile_content}>
                                 <div className={tileStyles.tile_top}>
                                    <h3 className={tileStyles.tile_header}>
                                       {item.Title}
                                       <small
                                          className={tileStyles.tile_subheader}
                                       >
                                          {item.ModelNumber && item.Manufacturer
                                             ? `${item.Manufacturer} | ${item.ModelNumber}`
                                             : item.Manufacturer}
                                       </small>
                                    </h3>

                                    <div className={tileStyles.tile_status}>
                                       <div
                                          className={
                                             tileStyles.tile_statusLabel
                                          }
                                       >
                                          {[2, 4].includes(item.ToolType)
                                             ? `${item.StatusQuantity} `
                                             : ''}
                                          {item.StatusDesc}
                                       </div>

                                       <div className={tileStyles.tile_icons}>
                                          {item.GPS && (
                                             <span title="Location Tracking">
                                                <Svg
                                                   id="locations"
                                                   width={16}
                                                   style={{
                                                      position: 'relative',
                                                      bottom: '1px',
                                                   }}
                                                   overrideViewBox="0 0 16 17"
                                                />
                                             </span>
                                          )}
                                          {item.Notifications > 0 && (
                                             <span title="Tasks">
                                                <Svg id="tasks" width={18} />
                                             </span>
                                          )}
                                          {item.NoteCount > 0 && (
                                             <span title="Notes">
                                                <Svg id="notes" width={15} />
                                             </span>
                                          )}
                                       </div>
                                    </div>
                                 </div>

                                 <div className={tileStyles.tile_image}>
                                    <Image
                                       width={100}
                                       url={item.PictureURL}
                                       alt={item.title}
                                       defaultImagePadding={20}
                                    />
                                 </div>
                                 <div className={tileStyles.tile_info}>
                                    <Row gutterWidth={15}>
                                       <Col xs={6} xxl={4}>
                                          <KeyValue
                                             className={
                                                tileStyles.tile_keyValue
                                             }
                                             label="Category"
                                             value={item.Category}
                                             svgId="cardViewCategory"
                                          />
                                       </Col>
                                       <Col xs={6} xxl={4}>
                                          <KeyValue
                                             className={
                                                tileStyles.tile_keyValue
                                             }
                                             label="Barcode"
                                             value={item.Barcode}
                                             svgId="cardViewBarcode"
                                          />
                                       </Col>
                                       <Col xs={6} xxl={4}>
                                          <KeyValue
                                             className={
                                                tileStyles.tile_keyValue
                                             }
                                             label="Serial number"
                                             value={item.SerialNumber}
                                             svgId="cardViewSerialNumber"
                                          />
                                       </Col>
                                       {settings.KitEnabled &&
                                          item.ToolType !== ToolTypes.Kit && (
                                             <Col xs={6} xxl={4}>
                                                <KeyValue
                                                   className={
                                                      tileStyles.tile_keyValue
                                                   }
                                                   label="Assigned to Kit"
                                                   value={
                                                      item.AssignedToKitName
                                                   }
                                                   svgId="cardViewAssignedToKit"
                                                />
                                             </Col>
                                          )}
                                       {settings.CustomText1Enabled && (
                                          <Col xs={6} xxl={4}>
                                             <KeyValue
                                                className={
                                                   tileStyles.tile_keyValue
                                                }
                                                label={
                                                   settings.CustomText1Label
                                                }
                                                value={item.CustomText1Value}
                                                svgId="cardViewText"
                                             />
                                          </Col>
                                       )}

                                       <Col xs={6} xxl={4}>
                                          {item?.GPS && (
                                             <KeyValue
                                                className={
                                                   tileStyles.tile_keyValue
                                                }
                                                label={'Last GPS Scan'}
                                                value={formatLocaleDate(
                                                   item.GPS.CreatedOn
                                                )}
                                                svgId="cardViewGps"
                                             />
                                          )}

                                          {item?.Bluetooth && (
                                             <KeyValue
                                                className={
                                                   tileStyles.tile_keyValue
                                                }
                                                label={'Last Bluetooth Ping'}
                                                value={item.CustomText1Value}
                                                svgId="cardViewBluetooth"
                                             />
                                          )}
                                       </Col>
                                    </Row>
                                 </div>
                              </div>
                              <div className={tileStyles.tile_footer}>
                                 <div className={tileStyles.tile_owner}>
                                    <span>
                                       <Svg id="assigned to" />
                                    </span>
                                    <span>
                                       {!!item?.AssignedToUser
                                          ? item?.AssignedToUser
                                          : item?.OwnerName}
                                    </span>
                                 </div>
                                 <div className={tileStyles.tile_locationDue}>
                                    {item.StatusDesc === 'Loaned' && (
                                       <div className={tileStyles.tile_dueBack}>
                                          <span>
                                             <Svg id="return tool" />
                                          </span>
                                          <span title="Date due back">
                                             {`Due ${
                                                item.DueBackDate ===
                                                   '2099-12-31T00:00:00' ||
                                                item.DueBackDate === null
                                                   ? '-'
                                                   : format(
                                                        new Date(
                                                           item.DueBackDate
                                                        ),
                                                        'MM/dd/yyyy'
                                                     )
                                             }`}
                                          </span>
                                       </div>
                                    )}

                                    <div className={tileStyles.tile_location}>
                                       {settings.EnableLocationTracking &&
                                          item?.CurrentLocation &&
                                          renderTileLocation(item)}
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </Col>
                     )
                  })
               }
            </Row>
         )}
      </>
   )
}

export default Tile
