import React, { memo } from 'react'
import { ProgressBar } from 'react-bootstrap'
import * as styles from './stackedProgressBar.module.scss'
import { StackedProgressBarProps, StackedProgressBarVariant } from './types'

const StackedProgressBar = ({ bars }: StackedProgressBarProps) => {
   const getBackgroundClass = (variant: StackedProgressBarVariant) => {
      switch (variant) {
         case 'success':
            return styles.success
         case 'info':
            return styles.info
         case 'warning':
            return styles.warning
         case 'danger':
            return styles.danger
         default:
            return styles.other
      }
   }
   return (
      <ProgressBar className={styles.wrapper}>
         {bars
            .filter((bar) => bar.percentage > 0)
            .map((bar, index) => (
               <ProgressBar
                  key={`${bar.variant}-${index}`}
                  className={getBackgroundClass(bar.variant)}
                  variant={bar.variant}
                  now={bar.percentage}
               />
            ))}
      </ProgressBar>
   )
}

export default memo(StackedProgressBar)
