import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'

import { PATH } from '../../constants/global'
import { useAPI } from '..'

import { UseConnections, Connection } from './types'

export default (): UseConnections => {
   const request = useAPI()

   const { data, isLoading, isError } = useQuery(
      'connections',
      getConnectionsFromAPI
   )

   async function getConnectionsFromAPI(): Promise<
      AxiosResponse<Connection[]>
   > {
      return await request.get(PATH.CONNECTIONS_FILTER.LIST)
   }

   return {
      list: data?.data || ([] as Connection[]),
      isLoading,
      isError,
   }
}
