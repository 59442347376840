import React, { memo, useMemo } from 'react'

import * as styles from './mapPin.module.scss'

import Svg from '../../svg/svg'
import { MapPinProps } from './types'
import { Tooltip } from '../..'
import MapInfoCardModal from '../mapInfoCardModal/mapInfoCardModal'

const MapPin = ({
   onClick,
   tooltip,
   svgId = 'pinNoShadow',
   svgWidth,
   svgFill,
   svgStroke,
   displayHighlight,
   infoCardId,
   isInfoCardOpen,
   handleCloseInfoCard,
   selectedLocation,
   reverseGeoLookUpResults,
}: MapPinProps) => {
   const fill = useMemo(
      () => (svgId === 'pinNoShadow' ? '#ffca05' : svgFill),
      [svgId, svgFill]
   )

   const stroke = useMemo(
      () => (svgId === 'pinNoShadow' ? 'none' : svgStroke),
      [svgId, svgStroke]
   )

   const width = useMemo(
      () => (svgId === 'pinNoShadow' ? 16 : svgWidth),
      [svgId, svgWidth]
   )
   return (
      <>
         <span className={styles.pin} onClick={onClick}>
            <Tooltip content={tooltip} placement="top-start">
               <Svg id={svgId} fill={fill} stroke={stroke} width={width} />
            </Tooltip>
         </span>
         {isInfoCardOpen && infoCardId === selectedLocation?.ID && (
            <MapInfoCardModal
               isInfoCardOpen={isInfoCardOpen}
               handleCloseInfoCard={handleCloseInfoCard}
               selectedLocation={selectedLocation}
               reverseGeoLookUpResults={reverseGeoLookUpResults}
            />
         )}
         {displayHighlight && <div className={styles.highlight}></div>}
      </>
   )
}

export default memo(MapPin)
